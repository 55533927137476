import axios from 'axios';
import Header from '../Header';
import { API_URL } from '../../Utils/Config';

class MoneyLinkService {

    getTransactionList(filter_data) {
        let data = JSON.stringify(filter_data);
        let headerObj =  { headers: Header.entityHeader() }
        if(filter_data.is_export == 1){
            headerObj.responseType = 'arraybuffer'
        }
        return axios.post(API_URL + 'transactions', data, headerObj)
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    getTransactionCount(filter_data) {
        let data = JSON.stringify(filter_data);
        return axios.post(API_URL + 'transactions-count', data, { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    makeRemoveStar(detail) {
        let data = JSON.stringify(detail);
        return axios.put(API_URL + 'transaction-star', data, { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    getInitialData = () => {
        return axios.get(API_URL + 'transactions-data', { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getTransactionDetail = (id) => {
        return axios.get(API_URL + 'transaction/'+id, { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getEvidenceList = (filter_data) => {
        let data = JSON.stringify(filter_data);
        return axios.post(API_URL + 'evidences', data, { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    getLinkedEvidenceList = (id) => {
        let data = JSON.stringify(id);
        return axios.post(API_URL + 'transaction-evidences', data, { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    saveMoneyLink = (details) => {
        let data = JSON.stringify(details);
        return axios.put(API_URL + 'transaction', data, { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    getVoucherList = (id) => {
        return axios.get(API_URL + 'transaction-vouchers/'+id, { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getLedgerNameList = () => {
        return axios.get(API_URL + 'ledgers', { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getLinkHistory = (detail) => {
        let data = JSON.stringify(detail);
        return axios.post(API_URL + 'link-history', data, { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    importLedgers = (detail) => {
        let ledgerData = JSON.stringify(detail);
        return axios.post(API_URL + 'tactical-ledger-import', ledgerData, { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    importCostCenters = (detail) => {
        let cost_center = JSON.stringify(detail);
        return axios.post(API_URL + 'tactical-cost-centre-import', cost_center, { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    downloadVouchers = (detail) => {
        let filterData = JSON.stringify(detail);
        return axios.post(API_URL + 'tactical-export', filterData, { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return 404;
        });
    }

    initiateFreshRecommendation = () => {
        return axios.get(API_URL + 'initiate-fresh-recommendation', { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getSuggestedEvidenceList = (filter_data) => {
        let data = JSON.stringify(filter_data);
        return axios.post(API_URL + 'suggested-evidences', data, { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getRecommendationMeta = () => {
        return axios.get(API_URL + 'recommendation-meta', { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getRecommendationMetateo = () => {
        return axios.get(API_URL + 'recommendation-meta', { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    showReportPdf = (url) => {
        return axios.get(url, { 
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
         })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    saveBulkLedger = (data) => {
        return axios.post(API_URL + 'transactions-voucher-entries', data, { headers: Header.entityHeader() })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return error;
            });
    }

    getCostcenterCategory = () => {
        return axios.get(API_URL + 'cost-centres', { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getCostCenters = (category_guid) => {
        return axios.get(API_URL + 'cost-centres/'+category_guid, { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getLedgerMaster = () => {
        return axios.get(API_URL + 'transaction-masters', { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getLinkedEvidenceSuggestedEvidenceAndVoucher = (transaction_id) => {
        return axios.get(API_URL + 'transaction/'+transaction_id, { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getMoneyLinkLedgerRecommendation = (transaction_id, merchant_id) => {
        let data = {
            transaction_id: parseInt(transaction_id),
            merchant_id: merchant_id
        }
        return axios.post(API_URL + 'money-link-ledger-recommendation', data, { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getPendingBills = (data) => {
        return axios.post(API_URL + 'bills_outstandings', data, { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }

    getBulkLedgerRecommendation = (data) => {
        return axios.post(API_URL + 'bulk-money-link-ledger-recommendation', data, { headers: Header.entityHeader() })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return error;
        });
    }
    resetTransaction = (id) =>{
        return axios.delete(API_URL + 'transaction/' + id , { headers: Header.entityHeader() })
        .then((response)=>{
            return response.data;
        }).catch((error)=>{
            return error;
        });
    }
    resetBulkTransaction = (data)=>{
        return axios.delete(API_URL + 'delete-bulk-transaction', {data:{"transaction_ids":data}, headers: Header.entityHeader()})
        .then((response)=>{
            return response.data;
        }).catch((error)=>{
            return error;
        });
    }
}

export default new MoneyLinkService();


