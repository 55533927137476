import React from 'react'
import { Accordion, Card, Form, Modal, ProgressBar, Table } from 'react-bootstrap';
import { withRouter } from 'react-router';
import recipt from '../../Assets/img/recipt.png';
import DocBoxTab from './DocBoxTab';
import LedgerTab from './LedgerTab';
import BusinessNarrationTab from './NarrationTab';
import SearchEvidenceTab from './SearchEvidencesTab';
import { LEDGER_ENTRIES_CODE, LINKING_CODE } from '../../Helpers/Constants';

class UnlinkedEvidences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lgShow: false,
      transaction_id: this.props.transaction_id,
      tabs_permission: []
    }
  }

  setLgShow = (val) => {
    this.setState({ lgShow: val })
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.transaction_id != nextProps.transaction_id) {
      //let testElements = document.getElementsByClassName('collapse');

      // if(this.props.ledger_entry_permission){  
      //   if(!document.getElementById("ledgerTab").classList.contains('show')) { 
      //     document.getElementById("ledgerTab").classList.add('show');
      //     document.getElementById("ledger-accordion-tab").click()
      //   }
      // } 

      // document.getElementById("searchTab").
      this.setState({ transaction_id: nextProps.transaction_id })

    }
  }

  componentDidMount() {
    const tabs_permission = [];
    if (this.props.features_permissions_list.includes(LINKING_CODE)) {
      tabs_permission.push("0")
    }

    if (this.props.ledger_entry_permission) {
      tabs_permission.push("3")
    }

    this.setState({ tabs_permission })
  }

  render() {
    console.log(this.props.evidence_list_loading)
    // console.log(this.state.tabs_permission)
    return (
      <>
        {/* <div className="col-xl-8 evi-right-col"> */}
        <div className="form-sec-inner-new position-relative evi-right-col">
          {/* <div className="form-sec-inner-new position-relative">   */}

            <Modal
              size="lg"
              show={this.state.lgShow}
              onHide={() => this.setLgShow(false)}
              aria-labelledby="example-modal-sizes-title-lg"
              centered
              className="doc-box-file-preview"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <img className="" src={recipt} />
              </Modal.Body>
            </Modal>


            {this.props.features_permissions_list.includes(LINKING_CODE) && <SearchEvidenceTab
              checkReadyStatus = {this.props.checkReadyStatus}
              handleLinkEvidence={this.props.handleLinkEvidence}
              toggleSaveData={this.props.toggleSaveData}
              toggleNoSupportingDocument={this.props.toggleNoSupportingDocument}
              per_page_items={this.props.per_page_items}
              setPageNo={this.props.setPageNo}
              evidence_tab={this.props.evidence_tab}
              changeEvidenceTab={this.props.changeEvidenceTab}
              searchFilter={this.props.searchFilter}
              applyFilter={this.props.applyFilter}
              editEvidence={this.props.editEvidence}
              viewEvidence={this.props.viewEvidence}
              showLinkHistory={this.props.showLinkHistory}
              isFormDirty={this.props.isFormDirty}
              read_only={this.props.read_only}
              show_filter={this.props.show_filter}
              search_toggle={this.props.search_toggle}
              transaction_id={this.props.transaction_id}
              features_permissions_list={this.props.features_permissions_list}
              transactionAmount={this.props.transactionAmount}
              backup_evidences={this.props.backup_evidences}
              markAsNoSupportingDocument={this.props.markAsNoSupportingDocument}
              evidence_list_loading={this.props.evidence_list_loading}
              getMoneyLinkLedgerRecommendation={this.props.getMoneyLinkLedgerRecommendation}
              ledger_recommendation_loading={this.props.ledger_recommendation_loading}
            />}

            {/* <DocBoxTab
                toggleNoSupportingDocument={this.props.toggleNoSupportingDocument}
            /> */}
            {/* <BusinessNarrationTab
              isFormDirty={this.props.isFormDirty}
              read_only={this.props.read_only}
            /> */}

            {this.props.ledger_entry_permission &&
              <LedgerTab
                isFormDirty={this.props.isFormDirty}
                ledgerNameList={this.props.ledgerNameList}
                removeLedger={this.props.removeLedger}
                is_valid_voucher={this.props.is_valid_voucher}
                is_valid_ledger={this.props.is_valid_ledger}
                // read_only={this.props.read_only && (this.props.transaction_status == 3 || this.props.transaction_status == 5)}
                read_only={this.props.read_only && (this.props.account_status == 4 || this.props.account_status == 5)}
                transactionDate={this.props.transactionDate}
                transactionAmount={this.props.transactionAmount}
                transaction_narration={this.props.transaction_narration}
                transactionType={this.props.transactionType}
                submit_click={this.props.submit_click}
                getMoneyLinkLedgerRecommendation={this.props.getMoneyLinkLedgerRecommendation}
                //saveAllocationData={(allocation_data) => this.props.saveAllocationData(allocation_data)}
                account_status={this.props.account_status}
                old_account_status={this.props.old_account_status}
                transaction_id={this.props.transaction_id}
                getLedgerMaster={this.props.getLedgerMaster}
                ledger_recommendation_loading={this.props.ledger_recommendation_loading}
               checkAccountedStatus={this.props.checkAccountedStatus}
              />
            }
          {/* </div> */}
        </div>
      </>
    )
  }
}

export default withRouter(UnlinkedEvidences);